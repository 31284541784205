import React from "react";
import styled from "styled-components";
import homeImage from "../assets/hero.png";
import hero from "../assets/Frame_801-transformed.webp";
import { useLanguage } from "./LanguageContext"; // Замените на путь к вашему контексту

export default function Hero() {
  const { language } = useLanguage();

  return (
    <Section id="hero">
      <div className="background">
        <img src={hero} alt="" />
      </div>
      <div className="content">
        <div className="title">
          <div className="hero-title">
          <h1>{language === "ru" ? "THE EAST CARAVAN" : "THE EAST CARAVAN"}</h1>
          <p className="herotit">
            {language === "ru"
              ? "Откройте для себя мир с нами и создайте свои незабываемые приключения. Мы - ваш проводник в удивительные уголки планеты, где каждый момент наполнен удивительными впечатлениями."
              : "Discover the world with us and create your unforgettable adventures. We are your guide to amazing corners of the planet, where every moment is filled with amazing experiences."}
          </p>
          </div>
          <a href="https://forms.amocrm.ru/rvzmlvv" className="cart-btn">
            {language === "ru" ? "ПОДОБРАТЬ ТУР" : "FIND A TOUR"}
          </a>
        </div>
      </div>
    </Section>
  );
}
const Section = styled.section`
  position: relative;
  margin-top: 4.3rem;
  width: 100%;
  height: 100%;

  .background {
    height: 155%;
    img {
      width: 100%;

      object-fit: cover;
      
    }
  }
  .content {
    height: 100%;
    width: 100%;
    position: absolute;
    top: -240px;
    z-index: 3;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    .title {
      color: white;
      h1 {
        font-size: 3rem;
        letter-spacing: 0.2rem;
      }
      p {
        text-align: center;
        padding: 0 30vw;
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
        font-size: 1.2rem;
      }
    }
    .search {
      display: flex;
      background-color: #ffffffce;
      padding: 0.5rem;
      border-radius: 0.5rem;
      .container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 1.5rem;
        label {
          font-size: 1.1rem;
          color: #03045e;
        }
        input {
          background-color: transparent;
          border: none;
          text-align: center;
          color: black;
          &[type="date"] {
            padding-left: 3rem;
          }

          &::placeholder {
            color: black;
          }
          &:focus {
            outline: none;
          }
        }
      }
      button {
        padding: 1rem;
        cursor: pointer;
        border-radius: 0.3rem;
        border: none;
        color: white;
        background-color: #4361ee;
        font-size: 1.1rem;
        text-transform: uppercase;
        transition: 0.3s ease-in-out;
        &:hover {
          background-color: #023e8a;
        }
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 980px) {
    height: 25rem;
    .background {
      img {
        height: 100%;
      }
    }
    .content {
      top: 0px;
      .title {
        h1 {
          font-size: 1.5rem;
        }
        p {
          font-size: 1rem;
          padding: 1vw;
        }
        .hero-title{
          position: relative;
          top: -110px;
        }

        .cart-btn {
          position: relative;
          top: 220px;
          
        }
      }
      .search {
        flex-direction: column;
        padding: 0.8rem;
        gap: 0.8rem;
        /* padding: 0; */
        .container {
          padding: 0 0.8rem;
          input[type="date"] {
            padding-left: 1rem;
          }
        }
        button {
          padding: 1rem;
          font-size: 1rem;
        }
        /* display: none; */
      }
    }
  }
`;
