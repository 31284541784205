import React, { useState, useEffect } from "react";
import { useLanguage } from "./LanguageContext";

const TourDate = () => {
  const { language } = useLanguage();
  const [springTourDates, setSpringTourDates] = useState([]);
  const [autumnTourDates, setAutumnTourDates] = useState([]);

  const fetchTourDates = async (url, setter) => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      const dateField = language === "en" ? "day_en" : "day_ru";
      const formattedDates = data.map((item) => item[dateField]);
      setter(formattedDates);
    } catch (error) {
      console.error("Error fetching tour dates:", error);
    }
  };

  useEffect(() => {
    fetchTourDates("https://back.theeastcaravan.com/tours/date/", setSpringTourDates);
    fetchTourDates("https://back.theeastcaravan.com/tours/dateautm/", setAutumnTourDates);
  }, [language]);

  return (
    <>
      <h2 className="titlestop" id="datetour">
        {language === "en" ? "Tour Dates" : "Даты туров"}
      </h2>
      <div className="container3">
        <div className="classic">
          {/* <div className="titletour">{language === "ru" ? "Весна" : "Spring"}</div> */}
          <ul className="daytour">
            {springTourDates.map((date, index) => (
              <li key={index}>{date}</li>
            ))}
          </ul>
          <a href="https://forms.amocrm.ru/rvzmlvv" className="cart-btn" id="btn-tour">
            {language === "en" ? "Submit Application" : "Оставить заявку"}
          </a>
        </div>
        <div className="wine">
          {/* <div className="titletour">{language === "ru" ? "Осень" : "Autumn"}</div> */}
          <ul className="daytour">
            {autumnTourDates.map((date, index) => (
              <li key={index}>{date}</li>
            ))}
          </ul>
          <a href="https://forms.amocrm.ru/rvzmlvv" className="cart-btn" id="btn-tour">
            {language === "en" ? "Submit Application" : "Оставить заявку"}
          </a>
        </div>
      </div>
    </>
  );
};

export default TourDate;
