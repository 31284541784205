import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  BsLinkedin,
  BsFacebook,
  BsTelegram,
  BsMailbox,
  BsMailbox2,
} from "react-icons/bs";
import { SiMaildotru } from "react-icons/si";
import { IoLogoWhatsapp } from "react-icons/io";
import { AiFillInstagram } from "react-icons/ai";
import { useLanguage } from "./LanguageContext"; // Замените на путь к вашему контексту

export default function Footer() {
  const { language } = useLanguage();
  const [phoneNumbers, setPhoneNumbers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://back.theeastcaravan.com/api/contact/");
        const data = await response.json();
        setPhoneNumbers(data);
      } catch (error) {
        console.error("Error fetching phone numbers:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <FooterContainer>
      <span>
        {language === "ru" ? "The East Caravan. " : "The East Caravan."}
      </span>
      <ul className="links">
        <li>
          <a href="/">{language === "ru" ? "Главная" : "Home"}</a>
        </li>
        <li>
          <a href="https://theeastcaravan.com/#services">{language === "ru" ? "Города" : "Cities"}</a>
        </li>
        <li>
          <a href="https://theeastcaravan.com/#recommend">{language === "ru" ? "Программы туров" : "Tour Programs"}</a>
        </li>
        <li>
          <a href="https://theeastcaravan.com/#contact">{language === "ru" ? "Обратная связь" : "Contact Us"}</a>
        </li>
        <li>
          <a href="https://theeastcaravan.com/#testimonials">{language === "ru" ? "Отзывы" : "Testimonials"}</a>
        </li>
        <li>
          <a href="/about">{language === "ru" ? "О нас" : "About Us"}</a>
        </li>
        <li>
        <a href={`tel:${phoneNumbers.length > 0 ? phoneNumbers[0].number_ru : ''}`} style={{fontWeight: "bold"}}>{phoneNumbers.length > 0 ? phoneNumbers[0].number_ru : ''}</a>
        </li>
        <li>
        <a href={`tel:${phoneNumbers.length > 0 ? phoneNumbers[0].number_uz : ''}`} style={{fontWeight: "bold"}}>{phoneNumbers.length > 0 ? phoneNumbers[0].number_uz : ''}</a>
        </li>
      </ul>
      <ul className="social__links">
        <li>
          <a href="https://www.facebook.com/theeastcaravan">
            <BsFacebook />
          </a>
        </li>
       
        <li>
          <a href="https://t.me/eastcaravan">
            <BsTelegram />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/the_east_caravan?igsh=MWh6bHMycjVjaGppYw==">
            <AiFillInstagram />
          </a>
        </li>
        <li>
          <a href="mailto:eastcaravantravel@gmail.com">
            <SiMaildotru />
          </a>
        </li>
        <li>
          <a href="tel:+79771451253">
            <IoLogoWhatsapp />
          </a>
        </li>
      </ul>
    </FooterContainer>
  );
}
const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-around;
  background-color: #d0d8ff;
  border-radius: 0.5rem;
  padding: 1.5rem;
  padding-top:3rem !important;
  padding-bottom:3rem !important;
margin: 1rem 0.2rem;
  ul {
    display: flex;
    list-style-type: none;
    gap: 1rem;
    li {
      a {
        text-decoration: none;
        color: black;
        transition: 0.3s ease-in-out;
        &:hover {
          color: #302ce9;
        }
      }
      svg {
        font-size: 1.1rem;
        transition: 0.3s ease-in-out;
        &:hover {
          color: #302ce9;
        }
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1024px) {
    flex-direction: column;
    gap: 2rem;
    ul {
      flex-direction: column;
    }
    .social__links {
      flex-direction: row;
    }
  }
`;
