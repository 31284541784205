import React, { useContext, useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";
import axios from "axios";
import { useLanguage } from "./LanguageContext";

const Accordion1 = () => {
  const { language } = useLanguage();
  const [data, setData] = useState([]);
  const [accordionData, setAccordionData] = useState([]);
  const [comesOutData, setComesOutData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tourData, setTourData] = useState({});
  const [price, setPrice] = useState("");
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null); // Состояние для отслеживания открытого аккордиона
  const { translations } = useLanguage();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://back.theeastcaravan.com/tours/api/tour/4/"
        );
        const data = response.data;

        setTourData(data.tour);
        setAccordionData(data.days);
        setComesOutData(data.comes_out);
        const daysData = response.data.days;
        setData(daysData);
        const parsedPrice = parseFloat(
          data.tour.price_for_one.replace("$", "")
        );
        setPrice(isNaN(parsedPrice) ? null : parsedPrice);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const processText = (text) => {
    const newText = text.replace(/●/g, "<br>●");
    return newText;
  };

  const handleAccordionChange = (index) => {
    setOpenAccordionIndex(index === openAccordionIndex ? null : index);
  };

  return (
    <div>
      {data.map((day, index) => (
        <Accordion
          key={index}
          defaultExpanded = {index == 0}
          onChange={() => handleAccordionChange(index)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index + 1}-content`}
            id={`panel${index + 1}-header`}
          >
            <Typography>{`${index + 1} ${
              language === "ru" ? "День" : "Day"
            }`}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    language === "ru"
                      ? processText(day.description_ru)
                      : processText(day.description_en),
                }}
              />
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default Accordion1;
