import React from "react";
import { useLanguage } from "./LanguageContext";
import IMG from "../assets/2024-03-21 19.48.43.jpg";
import IMG2 from "../assets/2024-03-21 19.50.22.jpg";
import IMG3 from "../assets/2024-03-21 19.50.29.jpg";
import IMG4 from "../assets/IMG_20230909_212634_235.jpg";

const Team = () => {
  const { translations, language } = useLanguage();

  return (
    <div class="container-t">
      <h1>{language === "ru" ? "Наша Команда" : "Our Team"}</h1>

      <div class="row-t">
        <div class="card-t">
          <img src={IMG4} alt="*" />
          <h2>Bekzod</h2>
          <p class="role-t">
            {language === "ru"
              ? "Автор маршрутов и SEO"
              : "Author of tourist routes and SEO"}
          </p>
          <p>
            {language === "ru"
              ? "Меня зовут Бекзод, я главный караванщик в Узбекистане. Я родился в Ташкенте и безумно люблю свой край! Люблю делать так, чтобы мои гости также наслаждались моей страной, как и я. Моя команда всегда с нетерпением ждет Вас в Узбекистане!"
              : "My name is Bekzod, I am the main caravan driver in Uzbekistan. I was born in Tashkent and I love my region madly! I like to make my guests enjoy my country as much as I do. My team is always looking forward to seeing you in Uzbekistan!"}
          </p>
          {/* <a href="tel:+880123456789">{language === "ru" ? "Позвонить" : "Call"}</a> */}
        </div>
        <div class="card-t">
          <img src={IMG} alt="*" />
          <h2>Pakhlavon</h2>
          <p class="role-t">
            {language === "ru" ? "Гид по Узбекистану" : "Guide to Uzbekistan"}
          </p>
          <p>
            {language === "ru"
              ? "Пахлавон, житель города Самарканд. Сертифицированный гид и эксперт по авторским маршрутам по Узбекистану. "
              : "Pakhlavon, resident of Samarkand. Certified guide and expert on author's routes in Uzbekistan."}
          </p>
          {/* <a href="tel:+880123456789">{language === "ru" ? "Позвонить" : "Call"}</a> */}
        </div>
        <div class="card-t">
          <img src={IMG2} alt="*" />
          <h2>Feruz</h2>
          <p class="role-t">
            {language === "ru" ? "Гид по Узбекистану" : "Guide to Uzbekistan"}
          </p>
          <p>
            {language === "ru"
              ? "Гид высшей категории - профессиональный экскурсовод с большим опытом работы и глубоким знанием истории и культуры региона. "
              : "The guide of the highest category is a professional tour guide with extensive experience and deep knowledge of the history and culture of the region."}
          </p>
          {/* <a href="tel:+880123456789">{language === "ru" ? "Позвонить" : "Call"}</a> */}
        </div>
        <div class="card-t">
          <img src={IMG3} alt="*" />
          <h2>Elena</h2>
          <p class="role-t">
            {language === "ru" ? "Гид по Узбекистану" : "Guide to Uzbekistan"}
          </p>
          <p>
            {language === "ru"
              ? "Она отличается приветливым и дружелюбным отношением к туристам, умеет подбирать интересные маршруты и предлагать увлекательные экскурсии. Гид высшей категории, профессионально владеет несколькими иностранными языками, что делает её популярной среди иностранных туристов."
              : "She has a welcoming and friendly attitude towards tourists, knows how to select interesting routes and offer exciting excursions. A guide of a higher category professionally speaks several foreign languages, which makes her popular among foreign tourists."}
          </p>
          {/* <a href="tel:+880123456789">{language === "ru" ? "Позвонить" : "Call"}</a> */}
        </div>
      </div>
    </div>

    // <div class="container-t">
    //   <div class="d-flex-t">
    //     <div class="card-t">
    //       <div class="card-img-t">
    //         <div class="flip-card-inner-t">
    //           <div class="flip-card-front-t">
    //             <img src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt=""/>
    //           </div>
    //           <div class="flip-card-back-t">
    //             <img src="https://media.giphy.com/media/l4KibWpBGWchSqCRy/giphy.gif" alt=""/>
    //           </div>
    //         </div>

    //       </div>

    //       <div class="card-body-t">
    //         <h3>Rami</h3>
    //         <small>Web developer</small>
    //       </div>
    //     </div>
    //     <div class="card-t">
    //       <div class="card-img-t">
    //         <div class="flip-card-inner-t">
    //           <div class="flip-card-front-t">
    //             <img src="https://images.pexels.com/photos/3973335/pexels-photo-3973335.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt=""/>
    //           </div>
    //           <div class="flip-card-back-t">
    //             <img src="https://media4.giphy.com/media/l3vR5Sghh85EBCnhC/giphy.gif?cid=ecf05e4786trkpqmnl0q080j0qcgt9dtxdvmr1x8fkvhjljd&rid=giphy.gif&ct=g" alt=""/>
    //           </div>
    //         </div>

    //       </div>

    //       <div class="card-body-t">
    //         <h3>John</h3>
    //         <small>Backend developer</small>
    //       </div>
    //     </div>
    //     <div class="card-t">
    //       <div class="card-img-t">
    //         <div class="flip-card-inner-t">
    //           <div class="flip-card-front-t">
    //             <img src="https://images.pexels.com/photos/718978/pexels-photo-718978.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt=""/>
    //           </div>
    //           <div class="flip-card-back-t">
    //             <img src="https://media4.giphy.com/media/3ogmaPGsQOruw/200w.webp?cid=ecf05e47vrhrj4vupwhwn77ymnclehhrb0q3kd7unplgvcfx&rid=200w.webp&ct=g" alt=""/>
    //           </div>
    //         </div>

    //       </div>

    //       <div class="card-body-t">
    //         <h3>Sonja</h3>
    //         <small>UX / UI Designer</small>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Team;
