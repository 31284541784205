import React from 'react'

const Loader = () => {
  return (
    <div>

<div class="containerr">
	<div class="loader"></div>
	<div class="loader"></div>
	<div class="loader"></div>
</div>


    </div>
  )
}

export default Loader