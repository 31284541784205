import React, { useState, useEffect } from "react";
import styled from "styled-components";
import logo from "../assets/WhatsApp.svg.png";

export default function ContactBubble() {
  const [isVisible, setIsVisible] = useState(true);

  const openTelegram = () => {
    // Замените "your_username" на ваш никнейм в Телеграме или полный URL, если вы используете бота.
    window.location.href = "https://wa.me/79339228585";
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      // Adjust the threshold based on your requirements
      const threshold = documentHeight - windowHeight;

      setIsVisible(scrollY < threshold);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <ContactButton onClick={openTelegram} isVisible={isVisible}>
      <img src={logo} alt="" />
    </ContactButton>
  );
}

const ContactButton = styled.div`
  position: fixed;
  cursor: pointer;
  z-index: 10;
  bottom: ${(props) => (props.isVisible ? "1rem" : "-7rem")};
  right: 2rem;
  transition: bottom 0.3s ease-in-out;
  img {
    height: 4rem;
  }
  border-radius: 4rem;
  background-color: #1900ff39;
  padding: 1rem;
`;
