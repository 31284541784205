import React, { memo, useState, useEffect, useRef } from "react";

const VideoEmbed = () => {
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsIframeLoaded(true);
          observer.disconnect();
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (observer && containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className="video-container"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh", // Example height, adjust as needed
        // background: "#000",
      }}
    >
      {isIframeLoaded && (
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/WGvTosSQ7Mg?si=BwOwoVdbLowPfpLj"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          style={{
            border: "none",
          }}
        ></iframe>
      )}
    </div>
  );
};

export default memo(VideoEmbed);