import React from 'react'
import image from '../assets/Destination1.png'

const Soon = () => {
  return (
    <div class="waviy" id='soon'>
        <img src={image} alt="" />
   <span>c</span>
   <span>o</span>
   <span>m</span>
   <span>i</span>
   <span>n</span>
   <span>g</span>
   <span>s</span>
   <span>o</span>
   <span>o</span>
   <span>n</span>
  </div>
  )
}

export default Soon