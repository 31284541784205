import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Accordion from "./Accordion";
import axios from "axios";
import ScrollToTop from "./ScrollToTop";
import ContactBubble from "./ContactBubble";
import Testimonials2 from "./Testimonials2";
import Gallery from "./Gallery";
import { useLanguage } from "./LanguageContext";
import Accordion1 from "./Accordion1";
import Accordion2 from "./Accordion2";
import Accordion3 from "./Accordion3";
import ImageCarousel from "./ImageCarousel";
import Testimonials from "./Testimonials";
import { Button, Modal } from "antd";

const ProductPage4 = () => {
  const { translations, language } = useLanguage();
  const [accordionData, setAccordionData] = useState([]);
  const [tourData, setTourData] = useState({});
  const [comesOutData, setComesOutData] = useState([]);
  const [wineTourPrices, setWineTourPrices] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [price, setPrice] = useState("");
  const [loading, setLoading] = useState(true);
  const [excludedSteps, setExcludedSteps] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [tourResponse, pricesResponse] = await Promise.all([
          axios.get("https://back.theeastcaravan.com/tours/api/tour/3/"),
          axios.get("https://back.theeastcaravan.com/tours/price/"),
        ]);
        const responseEX = await axios.get(
          "https://back.theeastcaravan.com/api/excluded/"
        );
        const excludedDATA = responseEX.data;

        const [tourDataResponse, wineTourPricesResponse] = await Promise.all([
          tourResponse.data,
          pricesResponse.data,
        ]);

        setTourData(tourDataResponse.tour);
        setAccordionData(tourDataResponse.days);
        setComesOutData(tourDataResponse.comes_out);
        setWineTourPrices(wineTourPricesResponse[0]);
        setExcludedSteps(excludedDATA);

        const parsedPrice = parseFloat(
          tourDataResponse.tour.price_for_one_classic.replace("", "")
        );
        setPrice(isNaN(parsedPrice) ? null : parsedPrice);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // Прокрутите вверх при изменении страницы
    window.scrollTo(0, 0);

    // Очистите слушателя при размонтировании компонента
    return () => {
      // Нет необходимости в дополнительных действиях здесь, но вы можете добавить их при необходимости
    };
  }, []);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("Content of the modal");
  const showModal = (text) => {
    setOpen(true);
    setModalText(text);
  };

  const showModal2 = (text) => {
    setOpen2(true);
    setModalText(text);
  };
  const handleOk = () => {
    setModalText("The modal will be closed after two seconds");
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setOpen2(false);
      setConfirmLoading(false);
    }, 500);
  };

  const handleOk2 = () => {
    setModalText("The modal will be closed after two seconds");
    setConfirmLoading(true);
    setTimeout(() => {
      
      setOpen2(false);
      setConfirmLoading(false);
    }, 500);
  };
  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  const handleCancel2 = () => {
    console.log("Clicked cancel button");
    setOpen2(false);
  };


  return (
    <>
      <Navbar />
      <ScrollToTop />
      <ContactBubble />
      <main className="container">
        {loading ? (
          <p>
            {translations && translations.loading
              ? translations.loading
              : "Loading..."}
          </p>
        ) : (
          <>
            <div className="left-column">
              <img
                data-image="red"
                className="active"
                src={`https://back.theeastcaravan.com${tourData.mainimg}`}
                alt={tourData.title}
              />
              <br />
              
              <span className="individ1">
                <div className="acors-container">
                  <div className="acor1">
                    <div className="titleacor">
                      {language === "ru"
                        ? `Классический Тур по Узбекистану:`
                        : `Classic Tour of Uzbekistan:`}
                    </div>
                    <Accordion1 />

                    {/* <span>
                  {price !== null
                    ? `${
                        language === "ru"
                          ? `Классический Тур oдноместное размещение`
                          : `Classic Tour single occupancy`
                      }: ${tourData.price_for_one_classic}$`
                    : translations && translations.loading}
                </span> */}
                  </div>

                  <div className="acor1">
                    <div className="titleacor">
                      {language === "ru"
                        ? `Винный Тур по Узбекистану:`
                        : `Wine Tour of Uzbekistan:`}
                    </div>
                    <Accordion2 />
                  </div>

                  {/* <div className="acor1">
                  <div className="titleacor">
                  {language === "ru"
                  ? `Бизнес Тур по Узбекистану:`
                  : `Bussines Tour of Uzbekistan:`}
                  </div>
                  <Accordion3 />
                </div> */}
                </div>
              </span>
              <div className="included-pc">
              <h2 className="titlestop">
        {language === "ru" ? "Что входит в стоимость" : "What's included in the price"}
      </h2>
              <p className="inclusions-title">
                {translations && translations.included}
              </p>
              <ul className="inclusions-list">
                  {comesOutData.map(
                    ({ id, description_ru, description_en }) => (
                      <li key={id}>
                        {language === "ru" ? description_ru : description_en}
                      </li>
                    )
                  )}
                  <h2 className="titlestop">
                    {language === "ru"
                      ? "Что не входит в стоимость"
                      : "What is not included in the price"}
                  </h2>
                  {excludedSteps.map(({ id, title_ru, title_en }) => (
                    <li key={id}>{language === "ru" ? title_ru : title_en}</li>
                  ))}
                </ul>
                </div>

              <div className="galleryall"></div>
            </div>

            <div className="right-column">
              <div className="product-description">
                <span>{translations && translations.tour}</span>
                <h1>
                  {language === "ru" ? tourData.title_ru : tourData.title_en}
                </h1>
                <p>
                  {language === "ru"
                    ? tourData.description_ru
                    : tourData.description_en}
                </p>
              </div>

              <span className="individ2">
                <div className="acors-container">
                  <div className="acor1">
                    <div className="titleacor">
                      {language === "ru"
                        ? `Классический Тур по Узбекистану:`
                        : `Classic Tour of Uzbekistan:`}
                    </div>
                    <Accordion1 />
                   
                    {/* <div className="product-price product-price-mobile">
                      <span>
                        {price !== null
                          ? `${
                              language === "ru"
                                ? `Классический Тур oдноместное размещение`
                                : `Classic Tour single occupancy`
                            }: ${tourData.price_for_one_classic}$`
                          : translations && translations.loading}
                      </span>
                      <a
                        href="https://forms.amocrm.ru/rvzmlvv"
                        className="cart-btn"
                      >
                        {language === "ru"
                          ? `Оставить заявку`
                          : `Submit application`}
                      </a>
                    </div> */}
                    <section className="section-m">
              <div class="container-fluid">
                <div class="container-m">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="card-m text-center">
                        <div class="title-m">
                          <i class="fa fa-paper-plane" aria-hidden="true"></i>
                          <h2>
                            {language === "ru"
                              ? "Классический Тур по Узбекистану"
                              : "Classic Tour of Uzbekistan"}
                          </h2>
                          <h2>
                            {" "}
                            {language === "ru"
                              ? "Группа от 2 до 6 человек"
                              : "Group from 2 to 6 people"}
                          </h2>
                        </div>
                        <div class="price-m">
                          <h4>
                            {" "}
                            {price !== null
                              ? `${language === "ru" ? `от` : `from`}: ${
                                  tourData.price_for_two_classic
                                }$`
                              : translations && translations.loading}
                          </h4>
                        </div>
                        <div class="option">
                          {/* <ul>
                  <li><i class="fa fa-check" aria-hidden="true"></i>10 GB Space</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>3 Domain Names</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>20 Email Address</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>Live Support</li>
                </ul> */}
                        </div>
                        <a href="https://forms.amocrm.ru/rvzmlvv">
                          {language === "ru"
                            ? `Оставить заявку`
                            : `Submit application`}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
                  </div>

                  <div className="acor1">
                    <div className="titleacor">
                      {language === "ru"
                        ? `Винный Тур по Узбекистану:`
                        : `Wine Tour of Uzbekistan:`}
                    </div>
                    <Accordion2 />
                   
                    {/* <div className="product-price product-price-mobile">
                      
                      <span>
                        {price !== null
                          ? `${
                              language === "ru"
                                ? `Винный Тур oдноместное размещение`
                                : `Wine Tour single occupancy`
                            }: ${wineTourPrices.price_for_one_wine}$`
                          : translations && translations.loading}
                      </span>
                      <div href="" className="cart-btn" onClick={showModal}>
                  {language === "ru" ? `Оставить заявку` : `Submit application`}
                </div>
                    </div> */}
                    <section className="section-m">
              <div class="container-fluid">
                <div class="container-m">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="card-m text-center">
                        <div class="title-m">
                          <i class="fa fa-paper-plane" aria-hidden="true"></i>
                          <h2>
                            {language === "ru"
                              ? "Винный Тур по Узбекистану"
                              : "Wine Tour in Uzbekistan"}
                          </h2>
                          <h2>
                            {" "}
                            {language === "ru"
                              ? "Группа от 2 до 6 человек"
                              : "Group from 2 to 6 people"}
                          </h2>
                        </div>
                        <div class="price-m">
                          <h4>
                            {" "}
                            {price !== null
                              ? `${language === "ru" ? `от` : `from`}: ${
                                  wineTourPrices.price_for_two_wine
                                }$`
                              : translations && translations.loading}
                          </h4>
                        </div>
                        <div class="option">
                          {/* <ul>
                  <li><i class="fa fa-check" aria-hidden="true"></i>10 GB Space</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>3 Domain Names</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>20 Email Address</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>Live Support</li>
                </ul> */}
                        </div>
                        <a href="https://forms.amocrm.ru/rvzmlvv">
                          {language === "ru"
                            ? `Оставить заявку`
                            : `Submit application`}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="included-mb">
                <h2 className="titlestop">
                  {language === "ru"
                    ? "Что входит в стоимость"
                    : "What's included in the price"}
                </h2>
                <p className="inclusions-title">
                  {translations && translations.included}
                </p>
                <ul className="inclusions-list">
                  {comesOutData.map(
                    ({ id, description_ru, description_en }) => (
                      <li key={id}>
                        {language === "ru" ? description_ru : description_en}
                      </li>
                    )
                  )}
                  <h2 className="titlestop">
                    {language === "ru"
                      ? "Что не входит в стоимость"
                      : "What is not included in the price"}
                  </h2>
                  {excludedSteps.map(({ id, title_ru, title_en }) => (
                    <li key={id}>{language === "ru" ? title_ru : title_en}</li>
                  ))}
                </ul>
              </div>
                  </div>

                  {/* <div className="acor1">
                  <div className="titleacor">
                  {language === "ru"
                  ? `Бизнес Тур по Узбекистану:`
                  : `Bussines Tour of Uzbekistan:`}
                  </div>
                  <Accordion3 />
                </div> */}
                </div>
              </span>

              <div className="product-configuration">
                <div>
                  <h2>{translations && translations.dayDescription}</h2>
                  <div className="accordion">
                    {accordionData.map((item, index) => (
                      <Accordion
                        key={index}
                        title={`${index + 1} ${
                          language === "ru" ? `День` : `Day`
                        }`}
                        content={
                          language === "ru"
                            ? item.description_ru
                            : item.description_en
                        }
                      />
                    ))}
                  </div>
                </div>
              </div>

              <span className="price-pc">
                {/* {language === "ru" ? "Индивидуальный тур" : "Individual tour"} */}
                {/* <div className="product-price">
                  <span>
                    {price !== null
                      ? `${
                          language === "ru"
                            ? `Классический Тур oдноместное размещение`
                            : `Classic Tour single occupancy`
                        }: ${tourData.price_for_one_classic}$`
                      : translations && translations.loading}
                  </span>
                  <div href="" className="cart-btn" onClick={showModal}>
                  {language === "ru" ? `Оставить заявку` : `Submit application`}
                </div>
                </div> */}
                {/* <div className="product-price">
                  <span>
                    {price !== null
                      ? `${
                          language === "ru"
                            ? `Классический Тур `
                            : `Classic Tour`
                        }: ${tourData.price_for_two_classic}$`
                      : translations && translations.loading}
                  </span>
                  <div href="" className="cart-btn" onClick={showModal}>
                    {language === "ru"
                      ? `Оставить заявку`
                      : `Submit application`}
                  </div>
                </div> */}
                
                <section className="section-m">
              <div class="container-fluid">
                <div class="container-m">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="card-m text-center">
                        <div class="title-m">
                          <i class="fa fa-paper-plane" aria-hidden="true"></i>
                          <h2>
                            {language === "ru"
                              ? "Классический Тур по Узбекистану"
                              : "Classic Tour of Uzbekistan"}
                          </h2>
                          <h2>
                            {" "}
                            {language === "ru"
                              ? "Группа от 2 до 6 человек"
                              : "Group from 2 to 6 people"}
                          </h2>
                        </div>
                        <div class="price-m">
                          <h4>
                            {" "}
                            {price !== null
                              ? `${language === "ru" ? `от` : `from`}: ${
                                  tourData.price_for_two_classic
                                }$`
                              : translations && translations.loading}
                          </h4>
                        </div>
                        <div class="option">
                          {/* <ul>
                  <li><i class="fa fa-check" aria-hidden="true"></i>10 GB Space</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>3 Domain Names</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>20 Email Address</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>Live Support</li>
                </ul> */}
                        </div>
                        <a href="https://forms.amocrm.ru/rvzmlvv">
                          {language === "ru"
                            ? `Оставить заявку`
                            : `Submit application`}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
                {/* Wine Tour prices */}
                {/* <div className="product-price">
                  <span>
                    {price !== null
                      ? `${
                          language === "ru"
                            ? `Винный Тур oдноместное размещение`
                            : `Wine Tour single occupancy`
                        }: ${wineTourPrices.price_for_one_wine}$`
                      : translations && translations.loading}
                  </span>
                  <div href="" className="cart-btn" onClick={showModal}>
                  {language === "ru" ? `Оставить заявку` : `Submit application`}
                </div>
                </div> */}
                {/* <div className="product-price">
                  <span>
                    {price !== null
                      ? `${language === "ru" ? `Винный Тур ` : `Wine Tour `}: ${
                          wineTourPrices.price_for_two_wine
                        }$`
                      : translations && translations.loading}
                  </span>
                  <div href="" className="cart-btn" onClick={showModal2}>
                    {language === "ru"
                      ? `Оставить заявку`
                      : `Submit application`}
                  </div>
                </div> */}
                <section className="section-m">
              <div class="container-fluid">
                <div class="container-m">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="card-m text-center">
                        <div class="title-m">
                          <i class="fa fa-paper-plane" aria-hidden="true"></i>
                          <h2>
                            {language === "ru"
                              ? "Винный Тур по Узбекистану"
                              : "Wine Tour in Uzbekistan"}
                          </h2>
                          <h2>
                            {" "}
                            {language === "ru"
                              ? "Группа от 2 до 6 человек"
                              : "Group from 2 to 6 people"}
                          </h2>
                        </div>
                        <div class="price-m">
                          <h4>
                            {" "}
                            {price !== null
                              ? `${language === "ru" ? `от` : `from`}: ${
                                  wineTourPrices.price_for_two_wine
                                }$`
                              : translations && translations.loading}
                          </h4>
                        </div>
                        <div class="option">
                          {/* <ul>
                  <li><i class="fa fa-check" aria-hidden="true"></i>10 GB Space</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>3 Domain Names</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>20 Email Address</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>Live Support</li>
                </ul> */}
                        </div>
                        <a href="https://forms.amocrm.ru/rvzmlvv">
                          {language === "ru"
                            ? `Оставить заявку`
                            : `Submit application`}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
              </span>
              {/* Submit application button */}
            </div>
          </>
        )}

        <>
          <Modal
            title="Оставить заявку"
            open={open}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
          >
            <section className="section-m">
              <div class="container-fluid">
                <div class="container-m">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="card-m text-center">
                        <div class="title-m">
                          <i class="fa fa-paper-plane" aria-hidden="true"></i>
                          <h2>
                            {language === "ru"
                              ? "Классический Тур по Узбекистану"
                              : "Classic Tour of Uzbekistan"}
                          </h2>
                          <h2>
                            {" "}
                            {language === "ru"
                              ? "Группа от 2 до 4 человек"
                              : "Group from 2 to 4 people"}
                          </h2>
                        </div>
                        <div class="price-m">
                          <h4>
                            {" "}
                            {price !== null
                              ? `${language === "ru" ? `от` : `from`}: ${
                                  tourData.price_for_two_classic
                                }$`
                              : translations && translations.loading}
                          </h4>
                        </div>
                        <div class="option">
                          {/* <ul>
                  <li><i class="fa fa-check" aria-hidden="true"></i>10 GB Space</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>3 Domain Names</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>20 Email Address</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>Live Support</li>
                </ul> */}
                        </div>
                        <a href="https://forms.amocrm.ru/rvzmlvv">
                          {language === "ru"
                            ? `Оставить заявку`
                            : `Submit application`}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Modal>
        </>
        <>
          <Modal
            title="Оставить заявку"
            open={open2}
            onOk={handleOk2}
            confirmLoading={confirmLoading}
            onCancel={handleCancel2}
          >
            <section className="section-m">
              <div class="container-fluid">
                <div class="container-m">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="card-m text-center">
                        <div class="title-m">
                          <i class="fa fa-paper-plane" aria-hidden="true"></i>
                          <h2>
                            {language === "ru"
                              ? "Винный Тур по Узбекистану"
                              : "Wine Tour in Uzbekistan"}
                          </h2>
                          <h2>
                            {" "}
                            {language === "ru"
                              ? "Группа от 2 до 4 человек"
                              : "Group from 2 to 4 people"}
                          </h2>
                        </div>
                        <div class="price-m">
                          <h4>
                            {" "}
                            {price !== null
                              ? `${language === "ru" ? `от` : `from`}: ${
                                  "1344"
                                }$`
                              : translations && translations.loading}
                          </h4>
                        </div>
                        <div class="option">
                          {/* <ul>
                  <li><i class="fa fa-check" aria-hidden="true"></i>10 GB Space</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>3 Domain Names</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>20 Email Address</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>Live Support</li>
                </ul> */}
                        </div>
                        <a href="https://forms.amocrm.ru/rvzmlvv">
                          {language === "ru"
                            ? `Оставить заявку`
                            : `Submit application`}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Modal>
        </>
      </main>
      <Testimonials />
      <ImageCarousel />

      <Footer />
    </>
  );
};

export default ProductPage4;
