import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Accordion from "./Accordion";
import axios from "axios";
import ScrollToTop from "./ScrollToTop";
import ContactBubble from "./ContactBubble";
import Testimonials4 from "./Testimonials4";
import Gallery from "./Gallery";
import { useLanguage } from "./LanguageContext";
import Accordion1 from "./Accordion1";
import ImageCarousel from "./ImageCarousel";
import Testimonials from "./Testimonials";
import { Button, Modal } from "antd";

const ProductPage = () => {
  const { translations, language } = useLanguage();
  const [accordionData, setAccordionData] = useState([]);
  const [tourData, setTourData] = useState({});
  const [comesOutData, setComesOutData] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [price, setPrice] = useState("");
  const [loading, setLoading] = useState(true);
  const [excludedSteps, setExcludedSteps] = useState([]);
  const [steps, setSteps] = useState([]);

  const API_URL_EXCLUDED = "https://back.theeastcaravan.com/api/excluded/";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://back.theeastcaravan.com/tours/api/tour/0/"
        );
        const responseEX = await axios.get(
          "https://back.theeastcaravan.com/api/excluded/"
        );
        const excludedDATA = responseEX.data;
        const data = response.data;

        setTourData(data.tour);
        setAccordionData(data.days);
        setComesOutData(data.comes_out);
        setExcludedSteps(excludedDATA);

        const parsedPrice = parseFloat(
          data.tour.price_for_one.replace("$", "")
        );
        setPrice(isNaN(parsedPrice) ? null : parsedPrice);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // Прокрутите вверх при изменении страницы
    window.scrollTo(0, 0);

    // Очистите слушателя при размонтировании компонента
    return () => {
      // Нет необходимости в дополнительных действиях здесь, но вы можете добавить их при необходимости
    };
  }, []);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("Content of the modal");
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setModalText("The modal will be closed after two seconds");
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 500);
  };
  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  return (
    <>
      <Navbar />
      <ScrollToTop />
      <ContactBubble />
      <main className="container">
        {loading ? (
          <p>
            {translations && translations.loading
              ? translations.loading
              : "Loading..."}
          </p>
        ) : (
          <>
            <div className="left-column">
              <img
                data-image="red"
                className="active"
                src={`https://back.theeastcaravan.com${tourData.mainimg}`}
                alt={tourData.title}
              />
              <br />
              <div className="included-pc">
                <h2 className="titlestop">
                  {language === "ru"
                    ? "Что входит в стоимость"
                    : "What's included in the price"}
                </h2>
                <p className="inclusions-title">
                  {translations && translations.included}
                </p>
                <ul className="inclusions-list">
                  {comesOutData.map(
                    ({ id, description_ru, description_en }) => (
                      <li key={id}>
                        {language === "ru" ? description_ru : description_en}
                      </li>
                    )
                  )}
                  <h2 className="titlestop">
                    {language === "ru"
                      ? "Что не входит в стоимость"
                      : "What is not included in the price"}
                  </h2>
                  {excludedSteps.map(({ id, title_ru, title_en }) => (
                    <li key={id}>{language === "ru" ? title_ru : title_en}</li>
                  ))}
                </ul>
              </div>
            </div>
            {/* <ul>
                  {excluded.map(({ id, title_ru, title_en, step }) => (
                    <li key={id}>{language === "ru" ? step.title_ru : step.title_en}</li>
                  ))}

                </ul> */}

            <div className="right-column">
              <div className="product-description">
                <span>{translations && translations.tour}</span>
                <h1>
                  {language === "ru" ? tourData.title_ru : tourData.title_en}
                </h1>
                <p>
                  {language === "ru"
                    ? tourData.description_ru
                    : tourData.description_en}
                </p>
              </div>

              <div className="product-configuration">
                <div>
                  <h2>{translations && translations.dayDescription}</h2>
                  <div className="accordion">
                    <Accordion1 />
                  </div>
                </div>
              </div>
              {/* <span
                className="product-price"
                style={{ fontSize: "20px", fontWeight: "bold" }}
              >
                {language === "ru"
                  ? "При группе от 2 до 12 человек"
                  : "For a group of 2 to 12 people"}
              </span> */}

              {/* <div className="product-price">
                <span>
                  {price !== null
                    ? `${
                        language === "ru"
                          ? `Одноместное размещение`
                          : `Single occupancy`
                      }: ${tourData.price_for_one_classic}$`
                    : translations && translations.loading}
                    <div></div>
                  <span style={{textDecoration:"line-through", color:'red'}}>1153$</span>

                </span>
              <a href="https://forms.amocrm.ru/rvzmlvv" className="cart-btn">
                {language === "ru" ? `Оставить заявку` : `Submit application`}
              </a>
              </div> */}
              <div className="included-mb">
                <h2 className="titlestop">
                  {language === "ru"
                    ? "Что входит в стоимость"
                    : "What's included in the price"}
                </h2>
                <p className="inclusions-title">
                  {translations && translations.included}
                </p>
                <ul className="inclusions-list">
                  {comesOutData.map(
                    ({ id, description_ru, description_en }) => (
                      <li key={id}>
                        {language === "ru" ? description_ru : description_en}
                      </li>
                    )
                  )}
                  <h2 className="titlestop">
                    {language === "ru"
                      ? "Что не входит в стоимость"
                      : "What is not included in the price"}
                  </h2>
                  {excludedSteps.map(({ id, title_ru, title_en }) => (
                    <li key={id}>{language === "ru" ? title_ru : title_en}</li>
                  ))}
                </ul>
              </div>

              <section className="section-m">
                <div class="container-fluid">
                  <div class="container-m">
                    <div class="row">
                      <div class="col-sm-4">
                        <div class="card-m text-center">
                          <div class="title-m">
                            <i class="fa fa-paper-plane" aria-hidden="true"></i>
                            <h2>
                              {language === "ru"
                                ? tourData.title_ru
                                : tourData.title_en}
                            </h2>
                            <h2>
                              {" "}
                              {language === "ru"
                                ? "Группа от 2 до 12 человек"
                                : "Group from 2 to 12 people"}
                            </h2>
                          </div>
                          <div class="price-m">
                            <h4>
                              {" "}
                              {price !== null
                                ? `${language === "ru" ? `от` : `from`}: ${
                                    tourData.price_for_two_classic
                                  }$`
                                : translations && translations.loading}
                            </h4>
                          </div>
                          <div class="option">
                            {/* <ul>
                  <li><i class="fa fa-check" aria-hidden="true"></i>10 GB Space</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>3 Domain Names</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>20 Email Address</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>Live Support</li>
                </ul> */}
                          </div>
                          <a href="https://forms.amocrm.ru/rvzmlvv">
                            {language === "ru"
                              ? `Оставить заявку`
                              : `Submit application`}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </>
        )}

        <>
          <Modal
            title="Оставить заявку"
            open={open}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
          >
            <section className="section-m">
              <div class="container-fluid">
                <div class="container-m">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="card-m text-center">
                        <div class="title-m">
                          <i class="fa fa-paper-plane" aria-hidden="true"></i>
                          <h2>
                            {language === "ru"
                              ? tourData.title_ru
                              : tourData.title_en}
                          </h2>
                          <h2>
                            {" "}
                            {language === "ru"
                              ? "Группа от 2 до 12 человек"
                              : "Group from 2 to 12 people"}
                          </h2>
                        </div>
                        <div class="price-m">
                          <h4>
                            {" "}
                            {price !== null
                              ? `${language === "ru" ? `от` : `from`}: ${
                                  tourData.price_for_two_classic
                                }$`
                              : translations && translations.loading}
                          </h4>
                        </div>
                        <div class="option">
                          {/* <ul>
                  <li><i class="fa fa-check" aria-hidden="true"></i>10 GB Space</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>3 Domain Names</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>20 Email Address</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>Live Support</li>
                </ul> */}
                        </div>
                        <a href="https://forms.amocrm.ru/rvzmlvv">
                          {language === "ru"
                            ? `Оставить заявку`
                            : `Submit application`}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Modal>
        </>
      </main>
      <Testimonials />
      <ImageCarousel />
      {/* <div className="galleryall2">
        <Gallery className="galleryall" />
      </div> */}
      <Footer />
    </>
  );
};

export default ProductPage;
