import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "./LanguageContext";
import Destination1 from "../assets/khiva-uzbekistan-cafe-terrassa-restaurant.png";
import Destination2 from "../assets/untitled-1-2-1024x683.jpg";
import Destination3 from "../assets/tours.jpg";
import Destination4 from "../assets/samar-bukh-xiva.jpeg";
import Destination5 from "../assets/chudesa-vostoka.jpeg";
import Destination6 from "../assets/avtor-tur.jpeg";
import Destination7 from "../assets/individual2.jpeg";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 10px; /* Добавлено, чтобы компенсировать внешние отступы */
`;

const Card = styled.div`
  text-align: center;
  border: 1px solid #64af3d;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 10px;
  flex: 1 0 calc(33% - 20px); /* Равномерное распределение на 4 колонки */
`;

const Image = styled.img`
  padding-top: 20px;
  max-width: 335px;
  max-height: 300px;
  border-bottom: 4px solid #64af3d;
  border-radius: 15px;
`;

const Title = styled.h2`
  margin: 10px 0;
`;

const Description = styled.h4`
  padding-left: 20px;
  padding-right: 20px;
  font-size: 15px;
`;

const Button = styled.button`
  margin: 20px;
  padding: 10px 20px;
  background-color: transparent;
  color: #64af3d;
  border-radius: 50px;
  border: 2px solid #64af3d;
  cursor: pointer;
  font-size: 16px;
`;

const data = [
  {
    image: Destination7,
    title_ru: "Индивидуальный Тур по - Узбекистану",
    subTitle_ru:
      "Тур по Городам Узбекистана Ташкент- Горы Амирсай -Самарканд -Буха. (Хива по выбору туриста)",
    title_en: "Individual Tour in Uzbekistan",
    subTitle_en:
      "Tour of the Cities of Uzbekistan Tashkent - Amirsai Mountains - Samarkand - Bukha. (Khiva at the tourist’s choice)",
    cost: "45,500",
    duration: "Approx 2-night trip",
    link: "/product4/",
  },
  {
    image: Destination1,
    title_ru: "Классический Тур По - Узбекистану",
    subTitle_ru: "На 8 ДНЕЙ 7 НОЧЕЙ Тур По Городам Ташкент-Самарканд-Бухара",
    title_en: "Classic Tour of Uzbekistan",
    subTitle_en: "8 Days 7 Nights Tour to Tashkent-Samarkand-Bukhara",
    cost: "38,800",
    duration: "Approx 2-night trip",
    link: "/product/",
  },
  {
    image: Destination5,
    title_ru: "Чудеса Востока на 6 ДНЕЙ 5 НОЧЕЙ",
    subTitle_ru: "Тур по Городам Самарканд-Бухара",
    title_en: "Wonders of the East for 6 DAYS 5 NIGHTS",
    subTitle_en: "Tour of the Cities of Samarkand-Bukhara",
    cost: "45,500",
    duration: "Approx 2-night trip",
    link: "/product6/",
  },
  {
    image: Destination4,
    title_ru: "Древние города Узбекистана на 5 дней 4 ночей.",
    subTitle_ru: "Тур по Городам Самарканд-Бухара-Хива",
    title_en: "Ancient cities of Uzbekistan for 5 days 4 nights.",
    subTitle_en: "Tour of the Cities of Samarkand-Bukhara-Khiva",
    cost: "45,500",
    duration: "Approx 2-night trip",
    link: "/product5/",
  },
  {
    image: Destination6,
    title_ru: "Классический Узбекистан на 7 ДНЕЙ 6 НОЧЕЙ",
    subTitle_ru: "Тур по Городам Ташкент-Самарканд-Бухара",
    title_en: "Classic Uzbekistan for 7 DAYS 6 NIGHTS",
    subTitle_en: "Tour of the Cities of Samarkand-Bukhara-Khiva",
    cost: "45,500",
    duration: "Approx 2-night trip",
    link: "/product7/",
  },
  {
    image: Destination2,
    title_ru: "Винный Тур по - Узбекистану",
    subTitle_ru: "На 8 ДНЕЙ 7 НОЧЕЙ Тур По Городам Ташкент-Самарканд-Бухара",
    title_en: "Wine Tour of Uzbekistan",
    subTitle_en: "8 Days 7 Nights Tour to Tashkent-Samarkand-Bukhara",
    cost: "54,200",
    duration: "Approx 2-night trip",
    link: "/product2/",
  },
  {
    image: Destination3,
    title_ru: "Бизнес Тур по - Узбекистану",
    subTitle_ru:
      "Тур по текстильным предприятиям По направлениям Ташкент Самарканд Бухара. Всё для вашего бизнеса",
    title_en: "Bussines Tour of Uzbekistan",
    subTitle_en:
      "Textile Factory Tour In directions Tashkent Samarkand Bukhara. Everything for your business",
    cost: "45,500",
    duration: "Approx 2-night trip",
    link: "/product3/",
  },
];

const Recommend = () => {
  const { language } = useLanguage();
  const navigate = useNavigate();

  const handleDetailsClick = (link) => {
    navigate(link);
  };

  return (
    <>
      <h2 className="titlestop">
        {language === "ru"
          ? "Тур программы по Узбекистану"
          : "Tour programs in Uzbekistan"}
      </h2>
      <Container>
        {data.map((destination, index) => (
          <Card key={index}  className="recommend">
            <Image src={destination.image} alt={destination.title} />
            <Title>
              {language === "ru" ? destination.title_ru : destination.title_en}
            </Title>
            <Description>
              {language === "ru"
                ? destination.subTitle_ru
                : destination.subTitle_en}
            </Description>
            <Button onClick={() => handleDetailsClick(destination.link)}>
              {language === "ru" ? "Подробнее" : "Read more"}
            </Button>
          </Card>
        ))}
      </Container>
    </>
  );
};

export default Recommend;
