import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Accordion from "./Accordion";
import axios from "axios";
import ScrollToTop from "./ScrollToTop";
import ContactBubble from "./ContactBubble";
import Testimonials2 from "./Testimonials2";
import Gallery from "./Gallery";
import { useLanguage } from "./LanguageContext";
import Accordion4 from "./Accordion4";
import ImageCarousel from "./ImageCarousel";
import Testimonials from "./Testimonials";
import { Button, Modal } from "antd";

const ProductPage = () => {
  const { translations, language } = useLanguage();
  const [accordionData, setAccordionData] = useState([]);
  const [tourData, setTourData] = useState({});
  const [comesOutData, setComesOutData] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [price, setPrice] = useState("");
  const [loading, setLoading] = useState(true);
  const [excludedSteps, setExcludedSteps] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://back.theeastcaravan.com/tours/api/tour/4/"
        );
        const responseEX = await axios.get(
          "https://back.theeastcaravan.com/api/excluded/"
        );
        const excludedDATA = responseEX.data;
        const data = response.data;

        setTourData(data.tour);
        setAccordionData(data.days);
        setComesOutData(data.comes_out);
        setExcludedSteps(excludedDATA);

        const parsedPrice = parseFloat(
          data.tour.price_for_one.replace("$", "")
        );
        setPrice(isNaN(parsedPrice) ? null : parsedPrice);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

      // Прокрутите вверх при изменении страницы
      window.scrollTo(0, 0);

      // Очистите слушателя при размонтировании компонента
      return () => {
        // Нет необходимости в дополнительных действиях здесь, но вы можете добавить их при необходимости
      };
  }, []);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };


  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("Content of the modal");
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setModalText("The modal will be closed after two seconds");
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 500);
  };
  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  return (
    <>
      <Navbar />
      <ScrollToTop />
      <ContactBubble />
      <main className="container">
        {loading ? (
          <p>
            {translations && translations.loading
              ? translations.loading
              : "Loading..."}
          </p>
        ) : (
          <>
            <div className="left-column">
              <img
                data-image="red"
                className="active"
                src={`https://back.theeastcaravan.com${tourData.mainimg}`}
                alt={tourData.title}
              />
              <br />
              <div className="included-pc">
              <h2 className="titlestop">
        {language === "ru" ? "Что входит в стоимость" : "What's included in the price"}
      </h2>
              <p className="inclusions-title">
                {translations && translations.included}
              </p>
              <ul className="inclusions-list">
                  {comesOutData.map(
                    ({ id, description_ru, description_en }) => (
                      <li key={id}>
                        {language === "ru" ? description_ru : description_en}
                      </li>
                    )
                  )}
                  <h2 className="titlestop">
                    {language === "ru"
                      ? "Что не входит в стоимость"
                      : "What is not included in the price"}
                  </h2>
                  {excludedSteps.map(({ id, title_ru, title_en }) => (
                    <li key={id}>{language === "ru" ? title_ru : title_en}</li>
                  ))}
                </ul>
                </div>
              <div className="galleryall">
              </div>
            </div>

            <div className="right-column">
              <div className="product-description">
                <span>{translations && translations.tour}</span>
                <h1>
                  {language === "ru" ? tourData.title_ru : tourData.title_en}
                </h1>
                <p>
                  {language === "ru"
                    ? tourData.description_ru
                    : tourData.description_en}
                </p>
              </div>

              <div className="product-configuration">
                <div>
                  <h2>{translations && translations.dayDescription}</h2>
                  <div className="accordion">
                    <Accordion4/>
                  </div>
                </div>
              </div>
              {/* <span className="product-price" style={{fontSize: '20px', fontWeight: 'bold'}}>
              {language === "ru" ? "Индивидуальные тур" : "Individual tour"}

              </span> */}
              
              {/* <div className="product-price">
                <span>
                  {price !== null
                    ? `${
                        language === "ru"
                          ? `Двухместное размещение`
                          : `Double occupancy`
                      }: 875$`
                    : translations && translations.loading}
                </span>
                <div href="" className="cart-btn" onClick={showModal}>
                  {language === "ru" ? `Оставить заявку` : `Submit application`}
                </div>
              </div>

              <span className="product-price" style={{fontSize: '20px', fontWeight: 'bold'}}>
              {language === "ru" ? "При группе 2-12 человек" : "For a group of 2-12 people"}

              </span> */}
              {/* <div className="product-price">
                <span>
                  {price !== null
                    ? `${
                        language === "ru"
                          ? `Одноместное размещение`
                          : `Single occupancy`
                      }: 880$`
                    : translations && translations.loading}
                </span>
              <a href="https://forms.amocrm.ru/rvzmlvv" className="cart-btn">
                {language === "ru" ? `Оставить заявку` : `Submit application`}
              </a>
              </div> */}
              {/* <div className="product-price">
                <span>
                  {price !== null
                    ? `${
                        language === "ru"
                          ? `Двухместное размещение`
                          : `Double occupancy`
                      }: 805$`
                    : translations && translations.loading}
                </span>
                <div href="" className="cart-btn" onClick={showModal}>
                  {language === "ru" ? `Оставить заявку` : `Submit application`}
                </div>
              </div> */}
              <div className="included-mb">
                <h2 className="titlestop">
                  {language === "ru"
                    ? "Что входит в стоимость"
                    : "What's included in the price"}
                </h2>
                <p className="inclusions-title">
                  {translations && translations.included}
                </p>
                <ul className="inclusions-list">
                  {comesOutData.map(
                    ({ id, description_ru, description_en }) => (
                      <li key={id}>
                        {language === "ru" ? description_ru : description_en}
                      </li>
                    )
                  )}
                  <h2 className="titlestop">
                    {language === "ru"
                      ? "Что не входит в стоимость"
                      : "What is not included in the price"}
                  </h2>
                  {excludedSteps.map(({ id, title_ru, title_en }) => (
                    <li key={id}>{language === "ru" ? title_ru : title_en}</li>
                  ))}
                </ul>
              </div>
<section className="section-m">
              <div class="container-fluid">
                <div class="container-m">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="card-m text-center">
                        <div class="title-m">
                          <i class="fa fa-paper-plane" aria-hidden="true"></i>
                          <h2>
                            {language === "ru"
                              ? tourData.title_ru
                              : tourData.title_en}
                          </h2>
                          <h2>
                            {" "}
                            {language === "ru"
                              ? "Группа от 2 до 12 человек"
                              : "Group from 2 to 12 people"}
                          </h2>
                        </div>
                        <div class="price-m">
                          <h4>
                            {" "}
                            {price !== null
                              ? `${language === "ru" ? `от` : `from`}: 
                                ${tourData.price_for_two_classic}$
                                `
                              : translations && translations.loading}
                          </h4>
                        </div>
                        <div class="option">
                          {/* <ul>
                  <li><i class="fa fa-check" aria-hidden="true"></i>10 GB Space</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>3 Domain Names</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>20 Email Address</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>Live Support</li>
                </ul> */}
                        </div>
                        <a href="https://forms.amocrm.ru/rvzmlvv">
                          {language === "ru"
                            ? `Оставить заявку`
                            : `Submit application`}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
              {/* <span className="product-price" style={{fontSize: '20px', fontWeight: 'bold'}}>
              {language === "ru" ? "При группе 15 человек" : "For a group of 15 people"}

              </span> */}
              {/* <div className="product-price">
                <span>
                  {price !== null
                    ? `${
                        language === "ru"
                          ? `Одноместное размещение`
                          : `Single occupancy`
                      }: ${tourData.price_for_one_classic}$`
                    : translations && translations.loading}
                </span>
              <a href="https://forms.amocrm.ru/rvzmlvv" className="cart-btn">
                {language === "ru" ? `Оставить заявку` : `Submit application`}
              </a>
              </div> */}
              {/* <div className="product-price">
                <span>
                  {price !== null
                    ? `${
                        language === "ru"
                          ? `Двухместное размещение`
                          : `Double occupancy`
                      }: ${tourData.price_for_two_classic}$`
                    : translations && translations.loading}
                </span>
                <div href="" className="cart-btn" onClick={showModal}>
                  {language === "ru" ? `Оставить заявку` : `Submit application`}
                </div>
              </div> */}
              
            </div>
          </>
        )}

<>
          <Modal
            title="Оставить заявку"
            open={open}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
          >
            <section className="section-m">
              <div class="container-fluid">
                <div class="container-m">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="card-m text-center">
                        <div class="title-m">
                          <i class="fa fa-paper-plane" aria-hidden="true"></i>
                          <h2>
                            {language === "ru"
                              ? tourData.title_ru
                              : tourData.title_en}
                          </h2>
                          <h2>
                            {" "}
                            {language === "ru"
                              ? "Группа от 2 до 12 человек"
                              : "Group from 2 to 12 people"}
                          </h2>
                        </div>
                        <div class="price-m">
                          <h4>
                            {" "}
                            {price !== null
                              ? `${language === "ru" ? `от` : `from`}: 
                                805$
                                `
                              : translations && translations.loading}
                          </h4>
                        </div>
                        <div class="option">
                          {/* <ul>
                  <li><i class="fa fa-check" aria-hidden="true"></i>10 GB Space</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>3 Domain Names</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>20 Email Address</li>
                  <li><i class="fa fa-check" aria-hidden="true"></i>Live Support</li>
                </ul> */}
                        </div>
                        <a href="https://forms.amocrm.ru/rvzmlvv">
                          {language === "ru"
                            ? `Оставить заявку`
                            : `Submit application`}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Modal>
        </>
      </main>
      <Testimonials />
      <ImageCarousel/>

      {/* <div className="galleryall2">
        <Gallery className="galleryall" />
      </div> */}
      <Footer />
    </>
  );
};

export default ProductPage;
